<template>
    <div  id="courseDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程教学</span>>
                <span>课程介绍</span>
            </div>

            <div class="boxTwo">
                <div style="margin: 10px;">
                 <img style="height: 100%;" :src="require('@/assets/img/courseTechingimg/zpssg.png')" alt="">
                </div>
               
                <div style="margin: 0.8%;width: 80%;">
                    <p style="font-size: 1.3rem;font-weight: bold;padding-bottom: 5px;">装配式施工</p>
                    <div style="width: 80%;height: 120px;background-color: #F8F9FB;padding: 10px;">
                        <p style="font-size: 14px;padding-top: 10px;"><span style="font-size: 14px;color: #666666;">课程发布时间:</span>2022-08-01</p>
                        <p style="font-size: 14px;padding-top: 20px;">
                            <span style="color: #666666;">来源组织:</span><span style="padding-right: 20px;">湖北城市建设职业技术学院</span>
                            <span style="color: #666666;">类别:</span ><span style="padding-right: 20px;">高职</span>
                            <span style="color: #666666;">专业:</span><span style="padding-right: 20px;">土木工程</span>
                            <span style="color: #666666;">课程:</span><span>装配式施工</span>
                        </p>
                        <p style="font-size: 14px;padding-top: 20px;">
                            <span style="color: #666666;">作者:</span><span style="padding-right: 20px;">程彩霞</span>
                            <span style="color: #666666;">课程依据:</span><span>《装配式混凝土结构施工》</span>
                        </p>
                    </div>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;margin-top:10px;">加入选修</el-button>
                    <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-top:10px;" @click="courseIntroductionDetails">课程</el-button>
                </div>
               
            </div>
            <div class="boxThree">
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">3212</span><br/><span style="font-size: 14px;font-weight: 400;color: #666666;">累计学习人数</span> </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon1.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">24</span><br/><span style="font-size: 14px;font-weight: 400;color: #666666;">课件数量</span> </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon2.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">32</span><br/><span style="font-size: 14px;font-weight: 400;color: #666666;">视频数量</span> </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon3.png')" alt="" srcset="">
                    </div>
                </div>
                <div class="count">
                    <div style="float: left;margin: 15px auto;padding: 10px;">
                        <p><span style="font-size: 24px;font-weight: bold;color: #333333;">32</span><br/><span style="font-size: 14px;font-weight: 400;color: #666666;">课程节数</span> </p>
                    </div>
                    <div style="float: right;margin: 15px auto;margin-right: 20px;">
                        <img :src="require('@/assets/img/courseTechingimg/icon4.png')" alt="" srcset="">
                    </div>
                </div>
            </div>
            <div class="boxFour">
                <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
                <el-tab-pane label="课程详情" name="first">
                    <div>
                        <p style="font-size: 16px;font-weight: 600;color: #333333;">课程介绍</p>
                        <p style="font-size: 14px;font-weight: 400;color: #333333;padding-top: 10px;line-height: 20px;">本课程围绕装配式建筑中的预制构件厂构件生产与管理、现场装配施工，重点分析生产、施工环节的人才培养规格，将岗位职业能力标准、全国装配式职业技能竞赛标准、1+X装配式建筑构件制作与安装职业技能等级证书考核要求融入教学，立足岗位，以生产、施工流程为主线，确立典型工作任务，分6个模块进行系统讲述。</p>
                        <p style="font-size: 16px;font-weight: 600;color: #333333;padding-top: 20px;">课程知识点</p>
                        <p style="font-size: 14px;font-weight: 400;color: #333333;padding-top: 10px;line-height: 20px;">模块一主要讲述装配式混凝土建筑的基本概念与特点、常见的预制构件、装配式混凝土建筑评价标准、国家产业政策与发展态势等基础知识；
                            <br><br>模块二主要讲述预制构件厂的相关布局及预制桁架钢筋混凝土叠合板、三明治夹心保温外墙板、预制楼梯等常见预制构件的生产工艺要求、方法和措施。
                            <br><br>模块三主要讲述预制水平构件和预制竖向构件运输过程中安全和成品防护等基本注意事项；预制构件的进场验收；预制构件的现场存放及管理要点。
                            <br><br>模块四主要讲述装配式施工现场准备、预制竖向构件与水平构件的吊装、灌浆作业、后浇混凝土施工、打胶作业等各阶段的工艺流程、施工方法、质量检验、施工安全、环境保护等。
                            <br><br>模块五主要对接1+X实操考核技能点，模拟施工现场在校内建筑装配式全产业链实训基地，真实地开展预制构件制作、构件吊装、灌浆、打胶等实操训练。
                            <br><br>模块六主要是实际工程案例。多个装配式建筑项目预制构件生产方案编制、施工方案编制、吊装专项施工方案编制、套筒灌浆专项施工方案编制供学生学习，拓宽知识面，培养学生方案编制能力。</p>
                        <!-- <p style="font-size: 14px;font-weight: 400;color: #333333;padding-top: 10px;line-height: 20px;">本课程围绕装配式建筑中的预制构件厂构件生产与管理、现场装配施工，重点分析生产、施工环节的人才培养规格，将岗位职业能力标准、全国装配式职业技能竞赛标准、1+X装配式建筑构件制作与安装职业技能等级证书考核要求融入教学，立足岗位，以生产、施工流程为主线，确立典型工作任务，分6个模块进行系统讲述。
                            <br><br>模块一主要讲述装配式混凝土建筑的基本概念与特点、常见的预制构件、装配式混凝土建筑评价标准、国家产业政策与发展态势等基础知识；
                            <br><br>模块二主要讲述预制构件厂的相关布局及预制桁架钢筋混凝土叠合板、三明治夹心保温外墙板、预制楼梯等常见预制构件的生产工艺要求、方法和措施。
                            <br><br>模块三主要讲述预制水平构件和预制竖向构件运输过程中安全和成品防护等基本注意事项；预制构件的进场验收；预制构件的现场存放及管理要点。
                            <br><br>模块四主要讲述装配式施工现场准备、预制竖向构件与水平构件的吊装、灌浆作业、后浇混凝土施工、打胶作业等各阶段的工艺流程、施工方法、质量检验、施工安全、环境保护等。
                            <br><br>模块五主要对接1+X实操考核技能点，模拟施工现场在校内建筑装配式全产业链实训基地，真实地开展预制构件制作、构件吊装、灌浆、打胶等实操训练。
                            <br><br>模块六主要是实际工程案例。多个装配式建筑项目预制构件生产方案编制、施工方案编制、吊装专项施工方案编制、套筒灌浆专项施工方案编制供学生学习，拓宽知识面，培养学生方案编制能力。</p>  -->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="课程目录" name="second">
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="" name="1">
                            <template #title>
                                <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">单元一  绪论</span>
                                </template>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第1节  绪论</span>  <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                        </el-collapse-item>
                    </el-collapse>
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="" name="2">
                            <template #title>
                                <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">单元二  预制构件生产</span>
                                </template>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第1节  预制构件生产1  桁架钢筋混凝土叠合板</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div  style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第2节  预制构件生产2   夹心保温外墙板</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div  style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第3节  预制构件生产3   预制楼梯</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                        </el-collapse-item>
                    </el-collapse>
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="" name="3">
                            <template #title>
                                <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">单元三   预制构件运输与堆放 </span>
                                </template>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第1节   预制构件运输与堆放</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                        </el-collapse-item>
                    </el-collapse>
                    <el-collapse v-model="activeNames" @change="handleChange">
                        <el-collapse-item title="" name="4">
                            <template #title>
                                <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">单元四   装配式混凝土结构施工 </span>
                                </template>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第1节   装配式混凝土结构施工1</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第2节   装配式混凝土结构施工2</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第3节   装配式混凝土结构施工3</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第4节   装配式混凝土结构施工4</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第5节   装配式混凝土结构施工5</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第6节   装配式混凝土结构施工6</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                                <div style="margin-left: 80px;margin-top: 10px;">
                                    <p><span>第7节   装配式混凝土结构施工7</span> <img style="vertical-align: middle;cursor: pointer;" :src="require('@/assets/img/courseTechingimg/palyer.png')" alt=""></p>
                                </div>
                        </el-collapse-item>
                    </el-collapse>
                </el-tab-pane>
                <el-tab-pane label="教师团队" name="third">
                    <div style="padding: 10px;display: table-cell;">
                        <p style="font-weight: 600;padding-left: 20px;">课程负责人</p>
                        <div style="display: table-cell;">
                        <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/chengcaixia.png')" :size=100></el-avatar>
                        <p style="text-align: center;">程彩霞</p>
                        </div>
                        
                    </div>
                    <div style="padding: 10px;margin-top: 20px;">
                        
                            <p style="font-weight: 600;padding-left: 20px;">教师团队</p>
                        
                        
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/yangmengxue.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">杨梦雪</p>
                        </div>
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/yuyang.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">余洋</p>
                        </div>
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/libing.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">李兵</p>
                        </div>
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/xujinfeng.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">徐锦枫</p>
                        </div>
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/chentianxu.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">陈天旭</p>
                        </div>
                        <div style="display: table-cell;padding-right: 20px;">
                            <el-avatar style="text-align: center;margin-top: 10px;" :src="require('@/assets/img/courseTechingimg/wangyangai.jpeg')" :size=100></el-avatar>
                            <p style="text-align: center;">王延该</p>
                        </div>
                        
                    </div>
                </el-tab-pane>
                <el-tab-pane label="课程评价" name="fourth">
                    <div style="display: flex;justify-content: space-between;">
                        <div style="float: left;height:480px;overflow-y: auto;" class="scoreClass">
                        <div style="margin-bottom: 20px;" v-for="(item,index) in userScore" :key="index">
                            <p>
                                 <el-avatar style="text-align: center;vertical-align: middle;" :src="item.userImg" ></el-avatar>
                            <span style="padding-left: 10px;color: #666666;">{{ item.userName }}</span>
                            <span style="padding-left: 10px;color: #666666;">{{ item.scoreDate }}</span>
                            <span >
                                <el-rate
                                style="margin-top: 8px;margin-left: 20px;display: initial;"
                                v-model="item.scoreValue"
                                disabled
                                text-color="#ff9900">
                                </el-rate>
                            </span>
                            </p>
                           
                            <p style="padding-top: 10px;width: 50%;color: #333333;">{{ item.scoreConten }}</p>
                            
                        </div>
                        
                    </div>
                    <div>
                        <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;float: right;" @click="scoreClick">我要评价</el-button>
                    </div>
                    </div>
                    
                    
                </el-tab-pane>
            </el-tabs>
            </div>

        </div>
        <el-dialog
        title="课程评价"
        v-model="dialogVisible" width="30%"  :modal="false"
        :before-close="handleClose">
        <el-form ref="Form" :model="loginForm" label-width="100px">
            <el-form-item label="课程满意度:">
                <el-rate :allow-half="true" style="margin-top: 10px;" v-model="loginForm.satisfaction" @change="changeRate"></el-rate>
            </el-form-item>
            <el-form-item label="课程评价:">
                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" v-model="loginForm.desc"></el-input>
            </el-form-item>
        </el-form>
        <div style="text-align: center;">
            <el-button  round style="margin-left: 10px;" @click="handleClose">取消</el-button>
            <el-button type="primary" round style="background-color: #76C8F6;border: none;margin-left: 10px;" @click="scoreClick">提交</el-button>
        </div>
        
        
            
        
        
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed,watch ,getCurrentInstance, ref} from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import {backUrl} from "@/js/util"
export default {
    setup () {
        const router = useRouter();
        const store = useStore();
        const that = getCurrentInstance();
        const state = reactive({
            activeName:'first',
            activeNames:[1],
            dialogVisible:false,
            loginForm :{
                    satisfaction:null,
                    desc:''
                },
            userScore:[
                {
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'草木',
                    scoreDate:'2022-05-12',
                    scoreValue:2,
                    scoreConten:'非常棒的课程，听下来有很大的收获非常棒的课程，听下来有很大的收获非常棒的课程，听下来有很大的收获非常棒的课程，听下来有很大的收获非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'城春',
                    scoreDate:'2022-05-12',
                    scoreValue:5,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'深邃',
                    scoreDate:'2022-05-12',
                    scoreValue:3,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'学号001',
                    scoreDate:'2022-05-12',
                    scoreValue:5,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'学号1250',
                    scoreDate:'2022-05-12',
                    scoreValue:4,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'学号1250',
                    scoreDate:'2022-05-12',
                    scoreValue:4,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },{
                    userImg:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png',
                    userName:'学号1250',
                    scoreDate:'2022-05-12',
                    scoreValue:4,
                    scoreConten:'非常棒的课程，听下来有很大的收获'
                },
            ]
        })
        const methods = {
            indexClick(){
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick(){
                router.push('/home/courseTeaching');
            },
            handleClick(){

            },
            handleChange(){

            },
            changeRate(e){
                console.log(state.loginForm);
                // let instance  = getCurrentInstance()
	            // that.ctx.$forceUpdate()
            },
            scoreClick(){
                state.dialogVisible = true;
                
            },
            handleClose(){
                state.dialogVisible = false;
            },
            courseIntroductionDetails(){
                router.push('/home/courseIntroductionDetails')
            },
        }
        return{
            ...toRefs(state),
            ...methods,
            
        }
    }
}

</script>
<style  lang="scss" scoped>
#courseDetails{
    height: 100%;
    position: relative;
    background: #F7F8FA;
    .box{
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }
    .breadCrumbs{
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .boxTwo{
        width: 100%;
        height: 250px;
        background: #fff;
        border-radius: 5px;
        display: flex;
        justify-content:flex-start;
    }
    .boxThree{
        width: 100%;
        border-radius: 5px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
    .count{
        width: 23%;
        height: 100px;
        background: #fff;
        border-radius: 5px;
    }
    .boxFour{
        width: 100%;
        height: 550px;
        border-radius: 5px;
        margin-top: 10px;
        background: #fff;
    }
    .demo-tabs{
        padding: 10px 15px 10px 15px;
        :deep .el-tabs__item{
           font-size: 18px !important;
        }
    }
   :deep .el-collapse-item__header{
        background: #F7F8FA;
    }
    :deep .el-collapse-item__wrap{
        border: none;
    }
    .scoreClass::-webkit-scrollbar {
        display: none;
    }
}



</style>
<style lang="scss">
.el-avatar>img {
    /* display: block; */
    height: 100%;
    width: 100%;
    vertical-align: middle;
}
</style>